body{
  font-family: 'Baloo Chettan 2', cursive !important;
  height: 100%;
  /* padding-top: 65px;  */
  /* line-height: 1 !important; */
}


.faq-header{
  font-family: 'Dancing Script', cursive;
}

.contact-icon{
  border-color:rgb(236,23,79)!important;
  border: 2px solid ;
  color:rgb(236,23,79);
  cursor: default;
}
.contact-icon:hover{
  background-color: rgb(236,23,79);
  color: white;
  transform: scale(1.2);
  -ms-transform: scale(1.2); 
  -webkit-transform: scale(1.2); 
  transition: .3s ease-in-out;
}

.nav-cart:hover{
  background-color:rgb(236,23,79) !important;
  color: white;
  transform: scale(1.2);
  -ms-transform: scale(1.2); 
  -webkit-transform: scale(1.2); 
  transition: .3s ease-in-out;

}


@media  only screen and (max-width: 768px){
  .text{
    font-size: 30px;
  }
  .upper-col{
    padding-bottom: 40px;
  }
  .contact-icon{
    margin-left: 25%;
    margin-right: 25%;
  }
  .contact-details{
    text-align: center;
  }
  .product-image{
    max-height: 330px !important;
  }
 
} 

@media only screen and (min-width: 768px) {
  .text{
    font-size: 18px;
  }
  .ourstory{
  width: 768px;
  text-indent:3em;
  }
 
}
.content{
  background: #606c88; 
  background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);  
  background: linear-gradient(to right, #3f4c6b, #606c88); 
}
.left-panel{
  width: 100%;
  background: #606c88;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3f4c6b, #606c88); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}



@media only screen and (max-width: 992px) {
  .content{
    width: 100%;
   padding-left: 5%;
   padding-right: 5%;
  }
  .left-panel{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .faq-header{
    font-size: 50px;
  }
 
}

@media only screen and (min-width: 992px) {
  .desc-container{
    max-width:1440px
  }
  
  .journal-title{
    font-size:40px
  }
  
  .journal-subTitle{
    font-size:24px
  }

  .youMayAlsoLike{
    padding-left: 20%;
    padding-right: 20%;
  }
 
  .content{
    width: calc(100% - 250px);
    padding-left: 20px;
    padding-right: 40px;
    margin-left:250px;
  }
  .left-panel{
    width: calc(100% - 250px);
    padding-left: 20px;
    padding-right: 40px;
    margin-left:250px

  }

  .faq-header{
    font-size: 70px;
  }
 
 
 
  .text{
    font-size: 25px;
  }
  .topbar{
    padding-left:50%!important;
  }
  .dashboard-view{
    padding-left: 10%;
    padding-right: 10%;
    
  }
  .didyouknow{
    padding-left: 10%;
    padding-right: 10%;
  }

  .benefits{
    padding-left: 10%;
    padding-right: 10%;
  }
  .checkout-row{
    padding-left: 10%;
    padding-right: 10%;
  }
}

.product-desc{
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 55px;
}

@media only screen and (min-width: 1200px) {
  .products-page{
    padding-left: 10%;
    padding-right: 10%;
  }
  
}



@media only screen and (max-width: 992px) {
  
  .topbar{
    padding-left:20px!important;
  }
}

@media (max-width: 1000px) and (min-width: 768px) {
  .contact-icon{
    display: none;
  }
 
 
}

.notfound{
  height: 100vh;
  min-height: 768px;

}
.slider-wrapper-outer{
  width: 100%;
  display: block;
}

.overlay{
  height: 50vh;
  min-height: 540px;
}



nav .navbar-nav li a:hover{
  color:rgb(236,23,79) !important;
  transition: .3s ease-in-out;
  } 
  
  a.nav-link.active:hover{
    color:white !important;
  }

  .eachRow:hover{
    color:rgb(236,23,79) !important;
  transition: .3s ease-in-out;
  cursor: pointer;
  }

.nav-link{
  font-size: 18px;
  padding-right:15px !important;
  padding-left:15px !important;
}
.cart{
  right:0 !important;
}
.nav-icons{
  font-size: 25px;
}

.login{
  color:black;
}
.signup{
  color: black;
}

.login:hover{
  cursor: pointer;
  text-decoration: none !important;
  color:rgb(236,23,79) !important
}
.signup:hover{
  cursor: pointer;
  text-decoration: none;
  color:rgb(236,23,79)

}

.frozzberies{
  height: 40px;
}
.topbar{
  transition:all .2s;
  height: 25px;
}

.acai-button{
  font-size: 14px !important;
  border-radius: 99px !important;
  transition: transform .1s !important;
}

.acai-button{
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2); 
 background-color: rgb(236,23,79) !important;
}

.shopnow-button{
  font-size: 25px !important;
  border-radius: 99px !important;
  transition: transform .1s !important;
}

.shopnow-button:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2); 
 background-color: rgb(236,23,79) !important;
 
}

.recipes{
  transition: all .4s ease-in-out;
  /* width:350px */
}
.recipes:hover{
  transform: scale(1.1);
}
.recipe_image{
  max-height: 350px;
}


.products{
  transition: all .4s ease-in-out;
}
.products:hover{
  transform: scale(1.1);
}
.nav-dropdown-menu {
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 100% !important;

}
.react-slidedown.my-dropdown-slidedown {
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

#contact{
  background: #e1eec3;  
  background: -webkit-linear-gradient(to bottom, #f05053, #e1eec3);  
  background: linear-gradient(to bottom, #f05053, #e1eec3); 
  }

  
  .product-image{
    transition: 0.5s ease; 
   
  }
  
  .product-image:hover{
    transform: scale(1.2);
  }

  .img-outer {
    overflow: hidden;
  }

  .img-inner {
    position: relative;

  }

  .item:hover {
    color: #f05053;
  }
  

.close-btn{
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.5s ease 0s;
}
.close-btn:hover{
  transform: rotate(90deg);
}

.item-delete-button{
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.5s ease 0s;
}
.item-delete-button:hover{
  transform: rotate(90deg);
  opacity: 0.8;
}


.inner-buttons-container{
  position: absolute; 
  bottom: 0px; 
  background-color: white;
  /* height: 205px; */
}

.dropdown{
  border: 1px solid white;
}

.navlink:active{
  background-color:rgb(236,23,79)
}

.header{
  animation: bounceIn;
  animation-duration: 3s; /* don't forget to set a duration! */
}

.breadcrumb{
  background-color: white !important;
  font-size: 15px !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: '>' !important;
}

.checkout-logout:hover {
  color: #f05053 !important;
}


@keyframes fade {
  0%{ opacity: 0 }
  100% { opacity: 1 }
}

.slider-wrapper-active{
  animation: fade 1s infinite;
}


.activeDot{
  color: #f05053 !important;
}

@keyframes fadeIn {
  0%{ opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeout{
  0%{opacity: 1;}
  100%{opacity: 0;}
}

.fadeIn{
  animation:fadeIn 0.5s ;
}
.fadeout{
  opacity:0;
  animation:fadeout 0.5s ;
}
.fullPageLoading_overlay{
background-color: #f05053 !important;

}

.user_order_item{
  cursor: pointer;
}
.user_order_item:hover{
  background-color: gainsboro;
}
.admin-each-product:hover{
  background-color: gainsboro;

}
.page-item.active .page-link{
  background-color: rgb(235,24,79)!important;
  color: white !important;
  border-color: rgb(235,24,79) !important;
}

.react-multi-carousel-dot-list{
  padding-bottom: 10px !important;
}
.nutrition-text{
  cursor: pointer;
  color: blue;
  width: 200px;
}
.nutrition-text:hover{
  text-decoration: underline;
}

.corner-ribbon{
  width: 150px;
  background: #e43;
  position: absolute;
  top: 25px;
  /* left: -50px; */
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 999;
}

.corner-ribbon.top-right{
  /* top: 25px; */
  right: -20px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.recipe_image{
  height:250px !important;
  object-fit: cover;
  width: 100% !important;
}
.test{
  height:250px !important;
  object-fit: cover;
  width: 100% !important;
}

.product-image{
  height: 350px !important;
  width: 350px !important;
  object-fit: cover;
}


.phone-link{
  color:rgb(236,23,79);
}

.contact-icon:hover .phone-link{
  color: white;

}

.admin-review-product-name:hover{
  text-decoration: underline;
  color:rgb(40,167,69) !important
}

@media only screen and (min-width: 500px) {
  .star-title{
    font-size: 18px;
    display: inline;
  }
  .star-modal{
    padding:1rem
  }
 
}

@media only screen and (max-width: 499px) {
  .star-font-size{
    font-size: 12px;
  }
  .star-box{
    padding:15px
  }
  .review-title{
    font-size: 20px !important;
  }
}


.parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 480px;

}

.reviewStars:hover{
  background: black;
  color:white;
  transition: .3s ease-in-out;

}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.paddingClass{
  max-width: 1440px;
}

.article-container:hover .article-overlay {
  min-height: 100%;
  background-color: rgb(228,162,132);
  opacity: 0.9;
}


.article-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  transition: .5s ease;
  min-height: 0px;
}

.article-title{
  color: white;
  padding: 5px;
  font-weight: 700;
}

.article-text {
  color:white;
  position: absolute;
  padding: 5px;
}

.read-more-button:hover{
  text-decoration: underline;
}

.blog-overlay{
  height: 50vh;
  min-height: 540px;
}